import dynamic from 'next/dynamic'
import { SanityHero } from '@data/sanity/queries/types/modules'
import Photo from '@components/photo'

const BaseLottieAnimation = dynamic(
  () => import('@components/lottie-animation')
)
const ComplexPortableText = dynamic(
  () => import('@components/complex-portable-text')
)

type HeroProps = SanityHero

const Hero = ({
  tagline,
  title,
  content,
  graphicType,
  image,
  lottieAssetUrl,
}: HeroProps) => {
  return (
    <section className="pt-8 pb-8 md:pt-14 md:pb-14 lg:pt-20 lg:pb-20">
      <div className="container">
        <div className="grid md:grid-cols-3 xl:grid-cols-2 gap-x-4 gap-y-8 sm:gap-x-12 lg:gap-x-14 lg:gap-y-14 items-center">
          <div className="order-1 md:order-0 md:col-span-2 space-y-4 xl:col-auto text-center md:text-left">
            <div className="space-y-2 lg:space-y-3">
              {tagline && <p className="text-base">{tagline}</p>}
              {title && <h1 className="xs:is-h2 lg:is-h1">{title}</h1>}
            </div>

            {content && (
              <ComplexPortableText content={content} className="rc" />
            )}
          </div>

          {(!graphicType || graphicType === 'image') && !!image && (
            <Photo
              image={image}
              imageClassName="object-cover object-left md:max-w-[300%] xl:max-w-[125%]"
            />
          )}
          {graphicType === 'lottieAnimation' && !!lottieAssetUrl && (
            <div className="md:w-[100vw] md:max-w-[300%] xl:max-w-[125%]">
              <BaseLottieAnimation assetUrl={lottieAssetUrl} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
